<template>
  <AppPage hideBottomBar hideAppBar container>
    <div class="auth-container">
      <div class="form-container">
        <v-form ref="form" v-model="isValid">
          <h2 class="mb-4">
            {{ $vuetify.lang.t("$vuetify.setup_new_password") }}
          </h2>

          <v-text-field
            v-model="password"
            outlined
            class="rounded-lg"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            :rules="[rules.required, rules.min]"
            :label="$vuetify.lang.t('$vuetify.password')"
            name="Password"
          ></v-text-field>

          <v-text-field
            v-model="password_confirmation"
            outlined
            class="rounded-lg"
            :append-icon="showPasswordC ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPasswordC ? 'text' : 'password'"
            @click:append="showPasswordC = !showPasswordC"
            :rules="[rules.required, rules.min, rules.match]"
            :label="$vuetify.lang.t('$vuetify.confirm_password')"
          ></v-text-field>
        </v-form>
      </div>

      <div class="mb-5">
        <app-button
          @click="createPassword"
          :disabled="!isValid"
          :loading="loading"
          >{{ $vuetify.lang.t("$vuetify.save") }}</app-button
        >
      </div>
    </div>

    <img src="@/assets/images/three-paws.png" class="three-paws" alt="" />
  </AppPage>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapState } = createNamespacedHelpers("user");
export default {
  name: "reset-password",
  data() {
    return {
      password: "",
      password_confirmation: "",
      showPassword: false,
      showPasswordC: false,
      isValid: false,
      rules: {
        required: (value) => !!value || "Field is required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        match: (v) => v === this.password || "Password do not match",
      },
    };
  },
  computed: {
    ...mapState(["loading"]),
  },
  methods: {
    ...mapActions(["RESET_PASSWORD"]),
    createPassword() {
      const { email } = this.$route.query;
      const { password, password_confirmation } = this;
      this.RESET_PASSWORD({
        email,
        password,
        password_confirmation,
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!to.query.email) {
      next("/forgot-password");
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
.auth-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100vh - 2rem);

  > div {
    width: 100%;
  }

  .form-container {
    flex: 1;
    display: grid;
    place-items: center;

    form {
      width: 100%;
    }
  }
}
</style>
